import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a63fe474 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _1408cd04 = () => interopDefault(import('../pages/help/about.vue' /* webpackChunkName: "pages/help/about" */))
const _34951706 = () => interopDefault(import('../pages/help/about02.vue' /* webpackChunkName: "pages/help/about02" */))
const _03031f08 = () => interopDefault(import('../pages/help/agent.vue' /* webpackChunkName: "pages/help/agent" */))
const _7104a33a = () => interopDefault(import('../pages/help/appset.vue' /* webpackChunkName: "pages/help/appset" */))
const _7ba458ff = () => interopDefault(import('../pages/help/check.vue' /* webpackChunkName: "pages/help/check" */))
const _49943e98 = () => interopDefault(import('../pages/help/disclaimer.vue' /* webpackChunkName: "pages/help/disclaimer" */))
const _36680294 = () => interopDefault(import('../pages/help/disclaimer02.vue' /* webpackChunkName: "pages/help/disclaimer02" */))
const _41939638 = () => interopDefault(import('../pages/help/maintain.vue' /* webpackChunkName: "pages/help/maintain" */))
const _c2f2c4a2 = () => interopDefault(import('../pages/help/novice.vue' /* webpackChunkName: "pages/help/novice" */))
const _657a15bf = () => interopDefault(import('../pages/help/question.vue' /* webpackChunkName: "pages/help/question" */))
const _33518356 = () => interopDefault(import('../pages/help/rule.vue' /* webpackChunkName: "pages/help/rule" */))
const _a5e70fd2 = () => interopDefault(import('../pages/help/rule02.vue' /* webpackChunkName: "pages/help/rule02" */))
const _d8a1b4c2 = () => interopDefault(import('../pages/history.vue' /* webpackChunkName: "pages/history" */))
const _6fa77f51 = () => interopDefault(import('../pages/history/hisActivity.vue' /* webpackChunkName: "pages/history/hisActivity" */))
const _13f1f33f = () => interopDefault(import('../pages/history/hisBet.vue' /* webpackChunkName: "pages/history/hisBet" */))
const _c44cf868 = () => interopDefault(import('../pages/history/hisDeposit.vue' /* webpackChunkName: "pages/history/hisDeposit" */))
const _219c36c6 = () => interopDefault(import('../pages/history/hisExchPoint.vue' /* webpackChunkName: "pages/history/hisExchPoint" */))
const _71e71589 = () => interopDefault(import('../pages/history/hisSale.vue' /* webpackChunkName: "pages/history/hisSale" */))
const _e26313a6 = () => interopDefault(import('../pages/history/hisTransfer.vue' /* webpackChunkName: "pages/history/hisTransfer" */))
const _042becaa = () => interopDefault(import('../pages/mobile/index.vue' /* webpackChunkName: "pages/mobile/index" */))
const _7b95bda0 = () => interopDefault(import('../pages/password.vue' /* webpackChunkName: "pages/password" */))
const _c464640c = () => interopDefault(import('../pages/password/chgpw.vue' /* webpackChunkName: "pages/password/chgpw" */))
const _d93deb72 = () => interopDefault(import('../pages/password/chgsafepw.vue' /* webpackChunkName: "pages/password/chgsafepw" */))
const _4621742a = () => interopDefault(import('../pages/share.vue' /* webpackChunkName: "pages/share" */))
const _677a7fe2 = () => interopDefault(import('../pages/share/hisShare.vue' /* webpackChunkName: "pages/share/hisShare" */))
const _4fa28bba = () => interopDefault(import('../pages/share/share.vue' /* webpackChunkName: "pages/share/share" */))
const _11912274 = () => interopDefault(import('../pages/system.vue' /* webpackChunkName: "pages/system" */))
const _2190e9ce = () => interopDefault(import('../pages/system/info.vue' /* webpackChunkName: "pages/system/info" */))
const _380100bc = () => interopDefault(import('../pages/system/mail.vue' /* webpackChunkName: "pages/system/mail" */))
const _67de5560 = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _1708571f = () => interopDefault(import('../pages/user/card.vue' /* webpackChunkName: "pages/user/card" */))
const _30b88e72 = () => interopDefault(import('../pages/user/payment.vue' /* webpackChunkName: "pages/user/payment" */))
const _4e0b86ea = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _07a3f25a = () => interopDefault(import('../pages/user/user.vue' /* webpackChunkName: "pages/user/user" */))
const _08589b4c = () => interopDefault(import('../pages/user/virtual.vue' /* webpackChunkName: "pages/user/virtual" */))
const _7e550f3e = () => interopDefault(import('../pages/wallet.vue' /* webpackChunkName: "pages/wallet" */))
const _c96191c6 = () => interopDefault(import('../pages/wallet/bank.vue' /* webpackChunkName: "pages/wallet/bank" */))
const _7614fd4d = () => interopDefault(import('../pages/wallet/deposit.vue' /* webpackChunkName: "pages/wallet/deposit" */))
const _5e874f22 = () => interopDefault(import('../pages/wallet/discount.vue' /* webpackChunkName: "pages/wallet/discount" */))
const _36a39590 = () => interopDefault(import('../pages/wallet/treasure.vue' /* webpackChunkName: "pages/wallet/treasure" */))
const _dfa9d18e = () => interopDefault(import('../pages/account/agent_signup.vue' /* webpackChunkName: "pages/account/agent_signup" */))
const _3fbe958a = () => interopDefault(import('../pages/account/forgot.vue' /* webpackChunkName: "pages/account/forgot" */))
const _43aad35c = () => interopDefault(import('../pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _338220ff = () => interopDefault(import('../pages/account/signup.vue' /* webpackChunkName: "pages/account/signup" */))
const _68988c4c = () => interopDefault(import('../pages/activity/activity.vue' /* webpackChunkName: "pages/activity/activity" */))
const _1bb37e54 = () => interopDefault(import('../pages/event/FIFA2022.vue' /* webpackChunkName: "pages/event/FIFA2022" */))
const _358f3878 = () => interopDefault(import('../pages/game/animal.vue' /* webpackChunkName: "pages/game/animal" */))
const _4d636880 = () => interopDefault(import('../pages/game/battle.vue' /* webpackChunkName: "pages/game/battle" */))
const _c5529b30 = () => interopDefault(import('../pages/game/chess.vue' /* webpackChunkName: "pages/game/chess" */))
const _bc01992c = () => interopDefault(import('../pages/game/chess02.vue' /* webpackChunkName: "pages/game/chess02" */))
const _7f6319bf = () => interopDefault(import('../pages/game/egame.vue' /* webpackChunkName: "pages/game/egame" */))
const _762d7387 = () => interopDefault(import('../pages/game/egame/egame.vue' /* webpackChunkName: "pages/game/egame/egame" */))
const _bce46bc0 = () => interopDefault(import('../pages/game/fish.vue' /* webpackChunkName: "pages/game/fish" */))
const _15c91ab4 = () => interopDefault(import('../pages/game/live.vue' /* webpackChunkName: "pages/game/live" */))
const _33e06231 = () => interopDefault(import('../pages/game/lottery.vue' /* webpackChunkName: "pages/game/lottery" */))
const _4d5dc5d8 = () => interopDefault(import('../pages/game/multiple.vue' /* webpackChunkName: "pages/game/multiple" */))
const _fef50a74 = () => interopDefault(import('../pages/game/slot.vue' /* webpackChunkName: "pages/game/slot" */))
const _2ffe98dc = () => interopDefault(import('../pages/game/sport.vue' /* webpackChunkName: "pages/game/sport" */))
const _5c0ab96c = () => interopDefault(import('../pages/mobile/game.vue' /* webpackChunkName: "pages/mobile/game" */))
const _d4ee11de = () => interopDefault(import('../pages/mobile/game/animal.vue' /* webpackChunkName: "pages/mobile/game/animal" */))
const _04980866 = () => interopDefault(import('../pages/mobile/game/battle.vue' /* webpackChunkName: "pages/mobile/game/battle" */))
const _4830017b = () => interopDefault(import('../pages/mobile/game/chess.vue' /* webpackChunkName: "pages/mobile/game/chess" */))
const _2a3c68d2 = () => interopDefault(import('../pages/mobile/game/egame.vue' /* webpackChunkName: "pages/mobile/game/egame" */))
const _90d666a6 = () => interopDefault(import('../pages/mobile/game/fish.vue' /* webpackChunkName: "pages/mobile/game/fish" */))
const _6e43865e = () => interopDefault(import('../pages/mobile/game/jackpot.vue' /* webpackChunkName: "pages/mobile/game/jackpot" */))
const _2bd01d41 = () => interopDefault(import('../pages/mobile/game/live.vue' /* webpackChunkName: "pages/mobile/game/live" */))
const _e4bb8ef8 = () => interopDefault(import('../pages/mobile/game/lottery.vue' /* webpackChunkName: "pages/mobile/game/lottery" */))
const _2bd6b9e5 = () => interopDefault(import('../pages/mobile/game/multiple.vue' /* webpackChunkName: "pages/mobile/game/multiple" */))
const _d2e7055a = () => interopDefault(import('../pages/mobile/game/slot.vue' /* webpackChunkName: "pages/mobile/game/slot" */))
const _4a503022 = () => interopDefault(import('../pages/mobile/game/sport.vue' /* webpackChunkName: "pages/mobile/game/sport" */))
const _0c5ae719 = () => interopDefault(import('../pages/mobile/help.vue' /* webpackChunkName: "pages/mobile/help" */))
const _823bc7d2 = () => interopDefault(import('../pages/mobile/help/about.vue' /* webpackChunkName: "pages/mobile/help/about" */))
const _e352254e = () => interopDefault(import('../pages/mobile/help/about02.vue' /* webpackChunkName: "pages/mobile/help/about02" */))
const _ad5080e2 = () => interopDefault(import('../pages/mobile/help/agent.vue' /* webpackChunkName: "pages/mobile/help/agent" */))
const _21553687 = () => interopDefault(import('../pages/mobile/help/appset.vue' /* webpackChunkName: "pages/mobile/help/appset" */))
const _267da812 = () => interopDefault(import('../pages/mobile/help/check.vue' /* webpackChunkName: "pages/mobile/help/check" */))
const _017bf4fe = () => interopDefault(import('../pages/mobile/help/disclaimer.vue' /* webpackChunkName: "pages/mobile/help/disclaimer" */))
const _36622443 = () => interopDefault(import('../pages/mobile/help/disclaimer02.vue' /* webpackChunkName: "pages/mobile/help/disclaimer02" */))
const _84a1ae1e = () => interopDefault(import('../pages/mobile/help/maintain.vue' /* webpackChunkName: "pages/mobile/help/maintain" */))
const _4ed730fc = () => interopDefault(import('../pages/mobile/help/novice.vue' /* webpackChunkName: "pages/mobile/help/novice" */))
const _43f309cc = () => interopDefault(import('../pages/mobile/help/question.vue' /* webpackChunkName: "pages/mobile/help/question" */))
const _07437e3c = () => interopDefault(import('../pages/mobile/help/rule.vue' /* webpackChunkName: "pages/mobile/help/rule" */))
const _5d5d0b64 = () => interopDefault(import('../pages/mobile/help/rule02.vue' /* webpackChunkName: "pages/mobile/help/rule02" */))
const _a6813128 = () => interopDefault(import('../pages/mobile/history.vue' /* webpackChunkName: "pages/mobile/history" */))
const _976b14c4 = () => interopDefault(import('../pages/mobile/history/hisActivity.vue' /* webpackChunkName: "pages/mobile/history/hisActivity" */))
const _049780d2 = () => interopDefault(import('../pages/mobile/history/hisBet.vue' /* webpackChunkName: "pages/mobile/history/hisBet" */))
const _7f080fdf = () => interopDefault(import('../pages/mobile/history/hisDeposit.vue' /* webpackChunkName: "pages/mobile/history/hisDeposit" */))
const _71580a19 = () => interopDefault(import('../pages/mobile/history/hisExchPoint.vue' /* webpackChunkName: "pages/mobile/history/hisExchPoint" */))
const _d4198b54 = () => interopDefault(import('../pages/mobile/history/hisSale.vue' /* webpackChunkName: "pages/mobile/history/hisSale" */))
const _591d270c = () => interopDefault(import('../pages/mobile/history/hisTransfer.vue' /* webpackChunkName: "pages/mobile/history/hisTransfer" */))
const _f6e4951a = () => interopDefault(import('../pages/mobile/password.vue' /* webpackChunkName: "pages/mobile/password" */))
const _e31948e6 = () => interopDefault(import('../pages/mobile/password/chgpw.vue' /* webpackChunkName: "pages/mobile/password/chgpw" */))
const _748f965a = () => interopDefault(import('../pages/mobile/password/chgsafepw.vue' /* webpackChunkName: "pages/mobile/password/chgsafepw" */))
const _53fc7592 = () => interopDefault(import('../pages/mobile/share.vue' /* webpackChunkName: "pages/mobile/share" */))
const _58200d75 = () => interopDefault(import('../pages/mobile/share/hisShare.vue' /* webpackChunkName: "pages/mobile/share/hisShare" */))
const _0019c1f2 = () => interopDefault(import('../pages/mobile/share/share.vue' /* webpackChunkName: "pages/mobile/share/share" */))
const _7dbaf307 = () => interopDefault(import('../pages/mobile/system.vue' /* webpackChunkName: "pages/mobile/system" */))
const _c0efc334 = () => interopDefault(import('../pages/mobile/system/info.vue' /* webpackChunkName: "pages/mobile/system/info" */))
const _d75fda22 = () => interopDefault(import('../pages/mobile/system/mail.vue' /* webpackChunkName: "pages/mobile/system/mail" */))
const _2b8baea3 = () => interopDefault(import('../pages/mobile/user.vue' /* webpackChunkName: "pages/mobile/user" */))
const _2d0f59ac = () => interopDefault(import('../pages/mobile/user/card.vue' /* webpackChunkName: "pages/mobile/user/card" */))
const _41658f1a = () => interopDefault(import('../pages/mobile/user/payment.vue' /* webpackChunkName: "pages/mobile/user/payment" */))
const _b0654586 = () => interopDefault(import('../pages/mobile/user/profile.vue' /* webpackChunkName: "pages/mobile/user/profile" */))
const _1daaf4e7 = () => interopDefault(import('../pages/mobile/user/user.vue' /* webpackChunkName: "pages/mobile/user/user" */))
const _621a719f = () => interopDefault(import('../pages/mobile/user/virtual.vue' /* webpackChunkName: "pages/mobile/user/virtual" */))
const _2b02405e = () => interopDefault(import('../pages/mobile/wallet.vue' /* webpackChunkName: "pages/mobile/wallet" */))
const _4b9fca6a = () => interopDefault(import('../pages/mobile/wallet/bank.vue' /* webpackChunkName: "pages/mobile/wallet/bank" */))
const _66ba8ae0 = () => interopDefault(import('../pages/mobile/wallet/deposit.vue' /* webpackChunkName: "pages/mobile/wallet/deposit" */))
const _fad91822 = () => interopDefault(import('../pages/mobile/wallet/discount.vue' /* webpackChunkName: "pages/mobile/wallet/discount" */))
const _5af8534c = () => interopDefault(import('../pages/mobile/wallet/hisDeposit.vue' /* webpackChunkName: "pages/mobile/wallet/hisDeposit" */))
const _37ba7dbb = () => interopDefault(import('../pages/mobile/wallet/hisSale.vue' /* webpackChunkName: "pages/mobile/wallet/hisSale" */))
const _5aafba5d = () => interopDefault(import('../pages/mobile/wallet/treasure.vue' /* webpackChunkName: "pages/mobile/wallet/treasure" */))
const _40322ae8 = () => interopDefault(import('../pages/mobile/account/agent_signup.vue' /* webpackChunkName: "pages/mobile/account/agent_signup" */))
const _3064231d = () => interopDefault(import('../pages/mobile/account/forgot.vue' /* webpackChunkName: "pages/mobile/account/forgot" */))
const _86b8eb42 = () => interopDefault(import('../pages/mobile/account/login.vue' /* webpackChunkName: "pages/mobile/account/login" */))
const _2427ae92 = () => interopDefault(import('../pages/mobile/account/signup.vue' /* webpackChunkName: "pages/mobile/account/signup" */))
const _1d49df67 = () => interopDefault(import('../pages/mobile/activity/activity.vue' /* webpackChunkName: "pages/mobile/activity/activity" */))
const _052e6d62 = () => interopDefault(import('../pages/mobile/activity/activity_old.vue' /* webpackChunkName: "pages/mobile/activity/activity_old" */))
const _3a68632e = () => interopDefault(import('../pages/mobile/event/FIFA2022.vue' /* webpackChunkName: "pages/mobile/event/FIFA2022" */))
const _b0b4a128 = () => interopDefault(import('../pages/a/_code/index.vue' /* webpackChunkName: "pages/a/_code/index" */))
const _744b9b9d = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/help",
    component: _a63fe474,
    name: "help",
    children: [{
      path: "about",
      component: _1408cd04,
      name: "help-about"
    }, {
      path: "about02",
      component: _34951706,
      name: "help-about02"
    }, {
      path: "agent",
      component: _03031f08,
      name: "help-agent"
    }, {
      path: "appset",
      component: _7104a33a,
      name: "help-appset"
    }, {
      path: "check",
      component: _7ba458ff,
      name: "help-check"
    }, {
      path: "disclaimer",
      component: _49943e98,
      name: "help-disclaimer"
    }, {
      path: "disclaimer02",
      component: _36680294,
      name: "help-disclaimer02"
    }, {
      path: "maintain",
      component: _41939638,
      name: "help-maintain"
    }, {
      path: "novice",
      component: _c2f2c4a2,
      name: "help-novice"
    }, {
      path: "question",
      component: _657a15bf,
      name: "help-question"
    }, {
      path: "rule",
      component: _33518356,
      name: "help-rule"
    }, {
      path: "rule02",
      component: _a5e70fd2,
      name: "help-rule02"
    }]
  }, {
    path: "/history",
    component: _d8a1b4c2,
    name: "history",
    children: [{
      path: "hisActivity",
      component: _6fa77f51,
      name: "history-hisActivity"
    }, {
      path: "hisBet",
      component: _13f1f33f,
      name: "history-hisBet"
    }, {
      path: "hisDeposit",
      component: _c44cf868,
      name: "history-hisDeposit"
    }, {
      path: "hisExchPoint",
      component: _219c36c6,
      name: "history-hisExchPoint"
    }, {
      path: "hisSale",
      component: _71e71589,
      name: "history-hisSale"
    }, {
      path: "hisTransfer",
      component: _e26313a6,
      name: "history-hisTransfer"
    }]
  }, {
    path: "/mobile",
    component: _042becaa,
    name: "mobile"
  }, {
    path: "/password",
    component: _7b95bda0,
    name: "password",
    children: [{
      path: "chgpw",
      component: _c464640c,
      name: "password-chgpw"
    }, {
      path: "chgsafepw",
      component: _d93deb72,
      name: "password-chgsafepw"
    }]
  }, {
    path: "/share",
    component: _4621742a,
    name: "share",
    children: [{
      path: "hisShare",
      component: _677a7fe2,
      name: "share-hisShare"
    }, {
      path: "share",
      component: _4fa28bba,
      name: "share-share"
    }]
  }, {
    path: "/system",
    component: _11912274,
    name: "system",
    children: [{
      path: "info",
      component: _2190e9ce,
      name: "system-info"
    }, {
      path: "mail",
      component: _380100bc,
      name: "system-mail"
    }]
  }, {
    path: "/user",
    component: _67de5560,
    name: "user",
    children: [{
      path: "card",
      component: _1708571f,
      name: "user-card"
    }, {
      path: "payment",
      component: _30b88e72,
      name: "user-payment"
    }, {
      path: "profile",
      component: _4e0b86ea,
      name: "user-profile"
    }, {
      path: "user",
      component: _07a3f25a,
      name: "user-user"
    }, {
      path: "virtual",
      component: _08589b4c,
      name: "user-virtual"
    }]
  }, {
    path: "/wallet",
    component: _7e550f3e,
    name: "wallet",
    children: [{
      path: "bank",
      component: _c96191c6,
      name: "wallet-bank"
    }, {
      path: "deposit",
      component: _7614fd4d,
      name: "wallet-deposit"
    }, {
      path: "discount",
      component: _5e874f22,
      name: "wallet-discount"
    }, {
      path: "treasure",
      component: _36a39590,
      name: "wallet-treasure"
    }]
  }, {
    path: "/account/agent_signup",
    component: _dfa9d18e,
    name: "account-agent_signup"
  }, {
    path: "/account/forgot",
    component: _3fbe958a,
    name: "account-forgot"
  }, {
    path: "/account/login",
    component: _43aad35c,
    name: "account-login"
  }, {
    path: "/account/signup",
    component: _338220ff,
    name: "account-signup"
  }, {
    path: "/activity/activity",
    component: _68988c4c,
    name: "activity-activity"
  }, {
    path: "/event/FIFA2022",
    component: _1bb37e54,
    name: "event-FIFA2022"
  }, {
    path: "/game/animal",
    component: _358f3878,
    name: "game-animal"
  }, {
    path: "/game/battle",
    component: _4d636880,
    name: "game-battle"
  }, {
    path: "/game/chess",
    component: _c5529b30,
    name: "game-chess"
  }, {
    path: "/game/chess02",
    component: _bc01992c,
    name: "game-chess02"
  }, {
    path: "/game/egame",
    component: _7f6319bf,
    name: "game-egame",
    children: [{
      path: "egame",
      component: _762d7387,
      name: "game-egame-egame"
    }]
  }, {
    path: "/game/fish",
    component: _bce46bc0,
    name: "game-fish"
  }, {
    path: "/game/live",
    component: _15c91ab4,
    name: "game-live"
  }, {
    path: "/game/lottery",
    component: _33e06231,
    name: "game-lottery"
  }, {
    path: "/game/multiple",
    component: _4d5dc5d8,
    name: "game-multiple"
  }, {
    path: "/game/slot",
    component: _fef50a74,
    name: "game-slot"
  }, {
    path: "/game/sport",
    component: _2ffe98dc,
    name: "game-sport"
  }, {
    path: "/mobile/game",
    component: _5c0ab96c,
    name: "mobile-game",
    children: [{
      path: "animal",
      component: _d4ee11de,
      name: "mobile-game-animal"
    }, {
      path: "battle",
      component: _04980866,
      name: "mobile-game-battle"
    }, {
      path: "chess",
      component: _4830017b,
      name: "mobile-game-chess"
    }, {
      path: "egame",
      component: _2a3c68d2,
      name: "mobile-game-egame"
    }, {
      path: "fish",
      component: _90d666a6,
      name: "mobile-game-fish"
    }, {
      path: "jackpot",
      component: _6e43865e,
      name: "mobile-game-jackpot"
    }, {
      path: "live",
      component: _2bd01d41,
      name: "mobile-game-live"
    }, {
      path: "lottery",
      component: _e4bb8ef8,
      name: "mobile-game-lottery"
    }, {
      path: "multiple",
      component: _2bd6b9e5,
      name: "mobile-game-multiple"
    }, {
      path: "slot",
      component: _d2e7055a,
      name: "mobile-game-slot"
    }, {
      path: "sport",
      component: _4a503022,
      name: "mobile-game-sport"
    }]
  }, {
    path: "/mobile/help",
    component: _0c5ae719,
    name: "mobile-help",
    children: [{
      path: "about",
      component: _823bc7d2,
      name: "mobile-help-about"
    }, {
      path: "about02",
      component: _e352254e,
      name: "mobile-help-about02"
    }, {
      path: "agent",
      component: _ad5080e2,
      name: "mobile-help-agent"
    }, {
      path: "appset",
      component: _21553687,
      name: "mobile-help-appset"
    }, {
      path: "check",
      component: _267da812,
      name: "mobile-help-check"
    }, {
      path: "disclaimer",
      component: _017bf4fe,
      name: "mobile-help-disclaimer"
    }, {
      path: "disclaimer02",
      component: _36622443,
      name: "mobile-help-disclaimer02"
    }, {
      path: "maintain",
      component: _84a1ae1e,
      name: "mobile-help-maintain"
    }, {
      path: "novice",
      component: _4ed730fc,
      name: "mobile-help-novice"
    }, {
      path: "question",
      component: _43f309cc,
      name: "mobile-help-question"
    }, {
      path: "rule",
      component: _07437e3c,
      name: "mobile-help-rule"
    }, {
      path: "rule02",
      component: _5d5d0b64,
      name: "mobile-help-rule02"
    }]
  }, {
    path: "/mobile/history",
    component: _a6813128,
    name: "mobile-history",
    children: [{
      path: "hisActivity",
      component: _976b14c4,
      name: "mobile-history-hisActivity"
    }, {
      path: "hisBet",
      component: _049780d2,
      name: "mobile-history-hisBet"
    }, {
      path: "hisDeposit",
      component: _7f080fdf,
      name: "mobile-history-hisDeposit"
    }, {
      path: "hisExchPoint",
      component: _71580a19,
      name: "mobile-history-hisExchPoint"
    }, {
      path: "hisSale",
      component: _d4198b54,
      name: "mobile-history-hisSale"
    }, {
      path: "hisTransfer",
      component: _591d270c,
      name: "mobile-history-hisTransfer"
    }]
  }, {
    path: "/mobile/password",
    component: _f6e4951a,
    name: "mobile-password",
    children: [{
      path: "chgpw",
      component: _e31948e6,
      name: "mobile-password-chgpw"
    }, {
      path: "chgsafepw",
      component: _748f965a,
      name: "mobile-password-chgsafepw"
    }]
  }, {
    path: "/mobile/share",
    component: _53fc7592,
    name: "mobile-share",
    children: [{
      path: "hisShare",
      component: _58200d75,
      name: "mobile-share-hisShare"
    }, {
      path: "share",
      component: _0019c1f2,
      name: "mobile-share-share"
    }]
  }, {
    path: "/mobile/system",
    component: _7dbaf307,
    name: "mobile-system",
    children: [{
      path: "info",
      component: _c0efc334,
      name: "mobile-system-info"
    }, {
      path: "mail",
      component: _d75fda22,
      name: "mobile-system-mail"
    }]
  }, {
    path: "/mobile/user",
    component: _2b8baea3,
    name: "mobile-user",
    children: [{
      path: "card",
      component: _2d0f59ac,
      name: "mobile-user-card"
    }, {
      path: "payment",
      component: _41658f1a,
      name: "mobile-user-payment"
    }, {
      path: "profile",
      component: _b0654586,
      name: "mobile-user-profile"
    }, {
      path: "user",
      component: _1daaf4e7,
      name: "mobile-user-user"
    }, {
      path: "virtual",
      component: _621a719f,
      name: "mobile-user-virtual"
    }]
  }, {
    path: "/mobile/wallet",
    component: _2b02405e,
    name: "mobile-wallet",
    children: [{
      path: "bank",
      component: _4b9fca6a,
      name: "mobile-wallet-bank"
    }, {
      path: "deposit",
      component: _66ba8ae0,
      name: "mobile-wallet-deposit"
    }, {
      path: "discount",
      component: _fad91822,
      name: "mobile-wallet-discount"
    }, {
      path: "hisDeposit",
      component: _5af8534c,
      name: "mobile-wallet-hisDeposit"
    }, {
      path: "hisSale",
      component: _37ba7dbb,
      name: "mobile-wallet-hisSale"
    }, {
      path: "treasure",
      component: _5aafba5d,
      name: "mobile-wallet-treasure"
    }]
  }, {
    path: "/mobile/account/agent_signup",
    component: _40322ae8,
    name: "mobile-account-agent_signup"
  }, {
    path: "/mobile/account/forgot",
    component: _3064231d,
    name: "mobile-account-forgot"
  }, {
    path: "/mobile/account/login",
    component: _86b8eb42,
    name: "mobile-account-login"
  }, {
    path: "/mobile/account/signup",
    component: _2427ae92,
    name: "mobile-account-signup"
  }, {
    path: "/mobile/activity/activity",
    component: _1d49df67,
    name: "mobile-activity-activity"
  }, {
    path: "/mobile/activity/activity_old",
    component: _052e6d62,
    name: "mobile-activity-activity_old"
  }, {
    path: "/mobile/event/FIFA2022",
    component: _3a68632e,
    name: "mobile-event-FIFA2022"
  }, {
    path: "/a/:code",
    component: _b0b4a128,
    name: "a-code"
  }, {
    path: "/",
    component: _744b9b9d,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
